<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminData.title')
          }}<v-btn
            color="#0c2c7c"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >{{ $t('Views.AdminData.createButton') }}
          </v-btn>
        </h3>
      </div>
      <simple-list
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="'Cargando registros'"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :showCreate="true"
        :showDelete="true"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" height="auto" :width="'80%'" :adaptive="true">
      <div class="card" style="padding: 2rem">
        <h3 class="text-center" v-if="flow == 'create'" style="margin-bottom: 20px">
          {{ $t('Views.AdminData.createText') }}
        </h3>
        <h3 class="text-center" v-if="flow == 'edit'" style="margin-bottom: 20px">
          {{ $t('Views.AdminData.editText') }}
        </h3>

        <div class="row">
          <v-form ref="form" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'Requerido']"
                :items="selectTypeData"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                :label="$t('Views.AdminData.labelType')"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter="100"
                :rules="descriptionRules"
                :label="$t('Views.AdminData.labelDescription')"
                v-model="description"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox label="Dato multiple" v-model="selection"></v-checkbox>
            </v-col> -->
            <v-col cols="12">
              <div class="row">
                <v-btn
                  :dark="valid"
                  style="margin: auto; margin-top: 10px"
                  v-if="flow == 'create'"
                  :disabled="!valid"
                  color="#0c2c7c"
                  @click="createItem()"
                >
                  {{ $t('Views.AdminData.createButton') }}
                </v-btn>
                <v-btn
                  :dark="valid"
                  style="margin: auto; margin-top: 10px"
                  v-if="flow == 'edit'"
                  :disabled="!valid"
                  color="#0c2c7c"
                  @click="saveItem()"
                >
                  {{ $t('Views.AdminData.saveButton') }}
                </v-btn>

                <v-btn
                  dark
                  style="margin: auto; margin-top: 10px"
                  color="#0c2c7c"
                  @click="reset"
                >
                  {{ $t('Views.AdminData.cleanButton') }}
                </v-btn>
                <v-btn
                  dark
                  style="margin: auto; margin-top: 10px"
                  color="#0c2c7c"
                  @click="$modal.hide('crudModal')"
                >
                  {{ $t('Views.AdminData.cancelButton') }}
                </v-btn>
              </div>
            </v-col>
          </v-form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  export default {
    name: 'Data',
    components: {
      SimpleList,
    },
    data() {
      return {
        loading: false,
        items: [],
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        companyType: 1,
        description: '',
        selection: false,
        type: '',
        selectTypeData: [
          { code: 13, name: 'INFORMACIÓN GENERAL' },
          { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
        ],
        valid: true,
        descriptionRules: [
          (v) => !!v || 'Requerido',
          (v) => v.length <= 100 || 'Máximo 100 caracteres',
        ],
      }
    },
    created() {
      this.getData()
    },
    computed: {
      headers() {
        return [
          { text: this.$t('Views.AdminData.headerDescription'), value: 'description' },
          { text: this.$t('Views.AdminData.headerType'), value: 'typeText' },
          { text: 'Acciones', value: 'actions', width: '100px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminData.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminData.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminData.orderOption3'),
            order: 'type',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminData.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminData.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getData()
      },
      async getData() {
        this.loading = true
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        axios.get('data?page=' + this.page + orderString).then((response) => {
          this.items = response.data.data
          if (this.items.length < 1) {
            this.loadingText = 'No hay registros'
          }
          this.totalItems = response.data.total
          this.itemsPerPage = response.data.per_page
          this.page = response.data.current_page
          this.pages = response.data.last_page
          this.items.map((x) => {
            let selectionableText
            switch (x.selection) {
              case 1:
                selectionableText = 'Si'
                break
              default:
                selectionableText = 'No'
                break
            }
            x.selectionableText = selectionableText
            let typeText
            switch (x.type) {
              case 7:
                typeText = 'UBICACIÓN Y DATOS DE CONTACTO'
                break

              case 13:
                typeText = 'INFORMACIÓN GENERAL'
                break
              default:
                typeText = ''
                break
            }
            let companyTypeText
            switch (x.company_type) {
              case 0:
                companyTypeText = 'Franquicias'
                break
              case 1:
                companyTypeText = 'Asociaciones de Franquicias'
                break
              case 2:
                companyTypeText = 'Consultores'
                break
              case 3:
                companyTypeText = 'Proveedores'
                break
              case 4:
                companyTypeText = 'Oferta Inmobiliaria'
                break
              case 5:
                companyTypeText = 'Negocios'
                break
              default:
                typeText = ''
                break
            }
            x.companyTypeText = companyTypeText
            x.typeText = typeText
          })
          this.loading = false
        })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        // this.companyType = 1
        this.description = ''
        this.type = ''
        // this.selection = false
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.companyType = this.item.company_type
        this.description = this.item.description
        this.type = this.item.type
        this.selection = this.item.selection
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.validate()
        axios
          .post('data', {
            company_type: this.companyType,
            description: this.description,
            type: this.type,
            selection: this.selection,
          })
          .then((response) => {
            this.getData()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.validate()
        axios
          .put('data/' + this.item.id, {
            company_type: this.companyType,
            description: this.description,
            type: this.type,
            selection: this.selection,
          })
          .then((response) => {
            this.getData()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        axios.delete('data/' + item.id).then((response) => {
          this.items = []
          this.getData()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getData()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
